import React from 'react'
import { Col } from 'react-bootstrap'
import {  Link } from "gatsby"
const TopProduct = ({productData}) => {
  const showProductImage = productData.productImage && productData.productImage.productImage;
  {/* top product box */}
  return ( 
     <Link to={`/products/${productData.slug}`}>
     <div className="tp-box mb-3">
        <div className="img-box"><img src={productData.productImage.productImage.sourceUrl} className="img-fluid" alt="" /></div>
        <h5 className="tp-title mt-4 mb-0">{productData.title}</h5>
      </div>
     </Link> 
  )
}

export default TopProduct
