import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import { FaLongArrowAltRight } from "react-icons/fa"
import { Parallax } from "react-parallax"
import ctaBgImg from "../../assets/images/cta-bg.jpg"

const CallToAction = () => {
  return (
    <section>
      <Parallax
        bgImage={ctaBgImg}
        bgImageAlt="Call to action"
        strength={250}
        className="cta-section"
      >
        <Container>
          <div className="cta-box" data-sal-duration="1000"  data-sal="slide-up"
  data-sal-easing="ease">
            <h2 className="mb-5" >
              If You Need Any Kind Of Lubricants...We Are Available For you
            </h2>
            <Link to="/contact" className="ct-btn1">
              Send Enquiry <FaLongArrowAltRight/>
            </Link>
          </div>
        </Container>
      </Parallax>
    </section>
  )
}

export default CallToAction
