import React from "react"
import { Carousel } from "react-bootstrap"
import bannerImg from "../../assets/images/home-banner1.jpg"
import bannerImg2 from "../../assets/images/home-banner2.jpg"

const HeroArea = () => {
  return (
    <Carousel className="hero-carousel">
      <Carousel.Item>
        <div className="carousel-item-wrapper">
          <div className="img-wrapper">
            <img src={bannerImg} />
          </div>
          <div className="detail-box  col-lg-7 col-xl-6 px-0 mx-auto">
            <h1>Shan Petro Oil</h1>
            <p>Customize right Lubricant as per technology from our technocrats who are well versed with latest technology</p>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-item-wrapper">
          <div className="img-wrapper">
            <img src={bannerImg2} />
          </div>
          <div className="detail-box  col-lg-7 col-xl-6 px-0 mx-auto">
            <h1>Shan Petro Oil</h1>
            <p> We provide best services and quality products with guidelines of correct product application</p>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  )
}

export default HeroArea
