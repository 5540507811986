// import { graphql } from "gatsby"
import React, { Fragment } from "react"
import LayoutAnimate from "../components/animation/LayoutAnimate"
import About from "../components/home/About"
import CallToAction from "../components/home/CallToAction"
import HeroArea from "../components/home/HeroArea"
import TopProductsSection from "../components/home/TopProducts/TopProductsSection" 

export default function Home({ data }) { 

// console.log('data',data.allWpProduct.edges);

//   const lookup = data.allWpProduct.edges.reduce((a, e) => {
//     a[e.node.slug] = ++a[e.node.slug] || 0;
//     return a;
//   }, {});
   
// console.log("-----------------duplicate-----------------",data.allWpProduct.edges.filter(e => lookup[e.node.slug]));



  return (
    <Fragment>
      <LayoutAnimate>
      <HeroArea />
      <About/>
      <CallToAction/>
      <TopProductsSection/>
      </LayoutAnimate>
    </Fragment>
  )
}

// export const pageQuery = graphql`
//   query {
//     allWpProduct {
//       edges {
//         node {
//           id
//           slug
//           wpChildren {
//             nodes {
//               id
//             }
//           }
//         }
//       }
//     }
//   }
// `

