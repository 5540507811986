import { StaticQuery, graphql } from "gatsby"
import React, { useRef } from "react"
import { Container } from "react-bootstrap"
import TopProduct from "./TopProduct"
import { Swiper, SwiperSlide } from "swiper/react" 
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"

const TopProductsSection = () => {
  const swiperRef = useRef(null)
  return (
    <StaticQuery
      query={graphql`
        query TopProductsQuery {
          allWpProduct(filter: { topProduct: { topProduct: { eq: "Yes" } } }) {
            nodes {
              title
              slug
              topProduct {
                topProduct
              }
              productImage {
                productImage {
                  sourceUrl
                }
              }
            }
          }
        }
      `}
      render={data => {
        // destructure top products from graphql response
        const {
          allWpProduct: { nodes: topProducts },
        } = data
        // console.log(topProducts)
        return (
          <section className="top-products-section layout-padding">
            <Container>
              <div className="heading-container heading-center">
              <h2 className="section-heading mb-5">
                Our Top Products
              </h2>
              </div>
              <Swiper
                spaceBetween={30}
                ref={swiperRef}
                loop={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },

                  991: {
                    slidesPerView: 3,
                  },
                }}
              >
                {topProducts.map(p => (
                  <SwiperSlide key={p.slug}>
                    <TopProduct productData={p} />
                  </SwiperSlide>
                ))}
                <div className="tp-carousel-btn-box">
                  <button
                    className="previousButton"
                    onClick={() => swiperRef.current.swiper.slidePrev()}
                  >
                    <FaAngleLeft />
                  </button>
                  <button
                    className="nextButton"
                    onClick={() => swiperRef.current.swiper.slideNext()}
                  >
                    <FaAngleRight />
                  </button>
                </div>
              </Swiper>
            </Container>
          </section>
        )
      }}
    />
  )
}

export default TopProductsSection
