import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { FaLongArrowAltRight } from "react-icons/fa"
import aboutImg from "../../assets/images/about.jpg"

const About = () => {
  return (
    <section className="about-section layout-padding">
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <div
              className="detail-box"
              data-sal-duration="1000"
              data-sal="slide-right"
              data-sal-easing="ease"
            >
              <div className="heading-container mb-3">
              <h2 className="section-heading">About Us</h2>
              </div>
              <p className="mb-4">
                Shan Petro is known as Technocommercial company and also well
                established company with young Technocrats who are well versed
                with Ultra Modern Technologies used around the world. The base
                of the company has strong foundation of Principles and
                Philosophy.
              </p>
              <Link to="/about" className="ct-btn1">
                Read More <FaLongArrowAltRight />
              </Link>
            </div>
          </Col>
          <Col lg={6} className="mt-5 mt-lg-0">
            <div
              className="img-box"
              data-sal-duration="1000"
              data-sal="slide-left"
              data-sal-easing="ease"
            >
              <img src={aboutImg} alt="about us image" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default About
